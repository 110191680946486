import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';

const Styles = styled.div`

	.blog-page {
        margin-top: 3.125rem;
        padding: 0 1.25rem;
    }

    .container {
        display: inline-block;
	}

	.subheading {
        margin: 0 0 1.25rem;
    }

	.grid-container {
        list-style: none;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-gap: 1.25rem;
    }

    .grid-item {
        background-size: cover;
        background-repeat: no-repeat;
        height: 15.75rem;
        width: 100%;
        box-shadow: 0 0 1.25rem rgb(0 0 0 / 30%);
        border-radius: .25rem;
        background-position: center;

        a {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            justify-content: flex-end;
            text-decoration: none;
            padding: 1.188rem;
            box-sizing: border-box;
            color: var(--white);
            background: rgba(0,0,0,0.4);
            position: relative;
            font-size: 1.5rem;

            p {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                text-align: center;
                font-weight: bold;
            }
        }
    }

	@media only screen and (min-width: 1024px) {

		.blog-page {
			padding: 0;
		}

		.err-head {
			max-width: 54.5rem;
		}

		.container {
			display: inline-block;
			margin-left: 50%;
			transform: translate(-50%);
		}

		.grid-container {
            grid-template-columns: 1fr 1fr 1fr;
        }

        .grid-item {
            height: 24.5rem;

            &:hover,
            &:focus-within {
                transform: scale(1.025);
                transform-origin: 50% 50%;
                transition: transform .3s ease-out;
            }
        }

	}
	
`;

export default function BlogPage({data}) {

	var blogposts = data.blogs.nodes;

	return (
		<Styles>
			<div className="pad">
				<section className="blog-page">
					<div className="container">
						<div className="col-1 hidden-m"></div>
						<div className="col-10">
							<h1>Inspiration</h1>
							<p className="subheading">
								If you need a little inspiration for your next trip, here are some articles from our team to guide your next adventure.
							</p>
							<ul className="grid-container">
                                {blogposts.map(function(item, index){
                                    return ( 
                                        <li key={ index } className="grid-item" style={{backgroundImage: "url(" + item.image.asset.url + ')'}}>
                                            <Link to={`/blog/${item.slug.current}`}>
                                                <p>{item.title}</p>
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
						</div>
					</div>
				</section>
			</div>
		</Styles>
	);
}


export const query = graphql`
	query {
		settings: sanitySiteSettings(_id: {eq: "LWHTsiteSettings"})  {
			description
			id
			logo {
				asset {
					fixed {
						src
					}
				}
			}
			favicon {
				asset {
					fixed(height: 48, width: 48) {
						src
					}
				}
			}
			image {
				asset {
				fixed(height: 400, width: 400) {
					base64
					srcWebp
					srcSetWebp
				}
				}
			}
			keywords
			primaryColor {
				hex
			}
			secondaryColor {
				hex
			}
			title
		}
		regions: allSanityLwhTregionPage {
			nodes {
				slug {
					current
				}
				name
				showInNav
			}
		}
		themes: allSanityLwhTtheme {
			nodes {
				name
				slug {
					current
				}
				showInNav
			}
		}
		blogs: allSanityBlogPost(filter: {app: {regex: "/LWHT/gi"}}) {
			nodes {
			app
			alt
			slug {
				current
			}
			title
			image {
				asset {
          			url
        		}
			}
			}
		}
	}
`